<template>
  <div style="height: 900px">
    <el-table
      :border="parentBorder"
      style="width: 100%; margin-top: 40px"
      height="850"
      :data="articles"
      row-key="id"
      stripe
      @expand-change="getQuestions"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div
            v-if="props.row.questions.length != 0"
            style="width: 670px; margin: 20px auto"
          >
            <el-card
              v-for="(question, index) in props.row.questions"
              v-bind:key="index"
              class="box-card"
              style="width: 670px; margin-top: 10px"
            >
              <template #header>
                <div
                  class="card-header"
                  style="display: flex; justify-content: space-between"
                >
                  <div>Question{{ index + 1 }}: {{ question.question }}</div>
                  <div>
                    Status:
                    <el-tag type="success">{{
                      getStatus(question.status).txt
                    }}</el-tag>
                  </div>
                </div>
              </template>
              <div>
                <el-descriptions :column="column">
                  <el-descriptions-item label="Choice1">
                    {{ question.choice1 }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Choice2">
                    {{ question.choice2 }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Choice3">
                    {{ question.choice3 }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Choice4">
                    {{ question.choice4 }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Correct Choice">
                    {{ getAns(question.correctChoice) }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <br />
            </el-card>
          </div>
          <el-empty v-else description="No Questions" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="title in tableTitleList"
        :prop="title.prop"
        :label="title.name"
        :key="title.prop"
        :index="title.index"
      >
      </el-table-column>
      <el-table-column label="Moderator" width="140">
        <template #default="scope">
          <el-tag type="warning">{{ scope.row.moderName || 'default' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="route.query.admin > 1" fixed="right" label="Operation" width="100">
        <template #default="scope">
          <div style="display: flex">
            <el-button
              type="danger"
              link
              size="small"
              @click="handleDelete(scope.row)"
              >Delete</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
import { ref } from "vue";
import { post } from "@/api/index";
import { formatDateTime } from "@/utils/dateFormatter";
import { getAns, getStatus } from "@/utils/codeToString";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  name: "Approved",
  setup() {
    const route = useRoute();
    const column = ref(2);
    const parentBorder = ref(false);
    const childBorder = ref(false);
    const tableTitleList = [
      { prop: "name", name: "Name" },
      { prop: "createTime", name: "CreateTime" },
      { prop: "topic", name: "Topic" },
      { prop: "category", name: "Category" },
      { prop: "overview", name: "Overview" },
      { prop: "uname", name: "User" },
    ];
    const articles = ref([]);
    const questions = ref("");
    const loading = ref(false);
    const uid = ref(-1);
    const uname = ref("");

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then((res) => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    function handleDelete(row) {
      ElMessageBox.confirm(
        "Are you sure to delete this article record?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          post("/admin/delete-article", { articleId: row.id,moderator: uid.value}).then((res) => {
            if (res.res == 1) {
              ElMessage({
                type: "success",
                message: "Delete completed",
              });
              load();
            } else {
              ElMessage({
                type: "error",
                message: "Error, delete canceled",
              });
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    }

   


    function getQuestions(row, expandedRows) {
      if (expandedRows.length > 0) {
        loading.value = true;
        post("/question/get-question", { articleId: row.id }).then((res) => {
          row.questions = res.questions;
          articles.value.forEach((element, index) => {
            if (element.id == row.id) {
              articles.value[index].questions = res.questions;
              loading.value = false;
            }
          });
        });
      }
    }

    function load() {
      post("/admin/approved-article",{moderator:uid.value}).then((res) => {
        articles.value = res.approved;
        const { userNames, moderators } = res;
        articles.value.forEach((article, index) => {
          article.questions = [];
          article.moderName = moderators[index] || "default";
          article.uname = userNames[index] || "undefined user";
          let old = new Date(article.createTime);
          article.createTime = formatDateTime(old, "yyyy-MM-dd HH:mm:ss");
        });
      });
    }

    load();

    return {
      parentBorder,
      childBorder,
      articles,
      tableTitleList,
      getQuestions,
      questions,
      loading,
      getAns,
      getStatus,
      column,
      handleDelete,
      route,
    };
  },
};
</script>




<style scoped></style>
